<template>
  <main class="text-center">
    <trac-loading v-if="isLoading" />
    <div class="" v-if="stores">
      <trac-modal v-if="addStoreModalCtrl" @close="addStoreModalCtrl = false">
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(createStore)"
            class="mt-10 text-left"
          >
            <div class="md:w-full p-8">
              <h3 class="mb-4">Store Info</h3>
              <div class="mt-5 w-full">
                <trac-validation-provider
                  name="store name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <!-- :disabled="store.name.toLowerCase() === 'main'" -->
                  <trac-input
                    v-model="store.name"
                    placeholder="Store Name"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
              <div class="mt-5 w-full">
                <trac-validation-provider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="store.email"
                    placeholder="Email Address"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
              <div class="mt-5 w-full">
                <trac-validation-provider
                  name="phone number"
                  rules="required|positive"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="store.phone"
                    placeholder="Phone Number"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
            </div>

            <div class="md:w-full p-8 mt-4">
              <h3 class="mb-4">Store Address</h3>
              <div class="mt-5 w-full">
                <trac-input
                  v-model="store.address1"
                  placeholder="Address Line 1"
                ></trac-input>
              </div>
              <div class="mt-5 w-full">
                <trac-input
                  v-model="store.address2"
                  placeholder="Address Line 2"
                ></trac-input>
              </div>
              <div class="mt-5 w-full">
                <trac-input
                  v-model="store.town"
                  placeholder="Town"
                ></trac-input>
              </div>
              <div class="mt-5 w-full">
                <trac-input
                  v-model="store.city"
                  placeholder="City"
                ></trac-input>
              </div>
              <div class="mt-5 w-full">
                <trac-validation-provider
                  name="state"
                  rules=""
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="store.state"
                    placeholder="State"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <ul class="flex-col mt-12 mb-8"></ul>
              <trac-button buttonType="submit">{{
                store._id ? "Update Store" : "Add Store"
              }}</trac-button>
            </div>
          </form>
        </trac-validation-observer>
      </trac-modal>
      <div class="text-left mb-4">
        <trac-back-button>Products</trac-back-button>
      </div>

      <h3 class="font-bold mb-4 text-left">Import product</h3>
      <div class="shadow p-4 w-4/5 mx-auto mt-8">
        <p class="text-xs mb-4">
          <a
            href="https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/39232814832442610Bulk-Product-Upload-Sheet.xlsx"
            download
            class="text-primaryBlue font-semibold cursor-pointer"
            >Download our bulk upload product template</a
          >, fill it, then upload your items below if you don’t have the
          template. It is best to add all the details before upload.
        </p>
      </div>

      <!-- <p class="my-6 text-sm">
        Kindly Select the stores you want to upload your products to:
      </p> -->
      <div
        class="
          flex
          items-start
          my-10
          border border-gray-300
          rounded
          text-left
          p-4
          w-4/5
          mx-auto
          justify-between
        "
      >
        <div class="grid grid-cols-4 flex-1">
          <div
            class="flex items-center text-xs mr-4"
            v-for="(store, index) in stores"
            :key="index"
          >
            <!-- <input
              type="checkbox"
              name=""
              :id="'store-' + index"
              :value="store._id"
            /> -->
            <label :for="'store-' + index" class="p-2 capitalize">{{
              store.name
            }}</label>
          </div>
        </div>

        <div class="">
          <button
            @click="addStoreModalCtrl = true"
            class="
              bg-none
              border-none
              flex
              items-center
              gap-2
              text-xs text-primaryBlue
              font-bold
            "
          >
            <span class="text-lg">+</span> Add Store
          </button>
        </div>
      </div>

      <div
        class="
          cursor-pointer
          border-2 border-dotted
          bg-transparentBackground
          p-10
          rounded-md
          flex
          items-center
          justify-center
          w-3/5
          mx-auto
        "
      >
        <img
          src="./../../assets/svg/product_bulk_upload_note.svg"
          alt=""
          class="mr-2"
          width="30"
        />
        <input
          type="file"
          name=""
          @input="selectBulkProductUploadDoc"
          id=""
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          class="hidden"
          ref="bulkUploadCtrl"
        />
        <p class="text-xs">
          Drag and drop your file here or <br />
          <span
            class="text-primaryBlue font-semibold"
            @click="$refs['bulkUploadCtrl'].click()"
            >Select it from your computer</span
          >
        </p>
      </div>

      <div class="mt-4">
        <p class="text-xs mb-5" v-if="bulkUploadData">
          {{ bulkUploadData.length }} product(s) to be uploaded.
        </p>
        <!-- Button -->
        <trac-button
          @button-clicked="uploadBulkProducts"
          :disabled="bulkUploadData.length === 0"
          class="px-20"
          >Upload</trac-button
        >
        <!-- Error response text -->
        <!-- <p v-if="!bulkUploadRes.status" class="text-secondaryRed text-xs">
          The file you are trying to upload is not the supported format, Kindly
          download the product template above.
        </p> -->
      </div>

      <div class="mt-10">
        <div class="text-xs mt-4 text-primaryGray">
          <p class="">
            {{ bulkUploadRes.message }}
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";
import readXlsxFile from "read-excel-file";

const schema = {
  "Title (Compulsory)": {
    prop: "title",
    type: String,
    required: true,
  },
  "Category (Compulsory)": {
    prop: "category",
    type: String,
    required: true,
  },
  "Price (Compulsory)": {
    prop: "price",
    type: Number,
    required: true,
  },
  "Description (Optional)": {
    prop: "description",
    type: String,
    required: true,
  },
  Manage_Stock: {
    prop: "manage_stock",
    required: true,
  },
  stock: {
    prop: "stock",
    type: Number,
    required: true,
  },
  "Store_name (Compulsory)": {
    prop: "store_name",
    type: String,
    required: true,
  },
};

export default {
  data() {
    return {
      isLoading: false,
      addStoreModalCtrl: false,
      stores: null,
      bulkUploadData: [],
      bulkUploadRes: false,
      store: {
        address1: "",
        address2: "",
        business_id: GET_USER_BUSINESS_ID(),
        business_name: GET_USER_BUSINESS_DATA().name,
        city: "",
        email: "",
        name: "",
        phone: "",
        register: false,
        state: "",
        town: "",
      },
    };
  },
  async created() {
    await this.fetchAllStores();
  },
  methods: {
    selectBulkProductUploadDoc(e) {
      if (e) {
        this.bulkUploadFile = e.target.files[0];
        this.convertExcelFileToJSObjec();
      }
    },
    resetBulkUploadData() {
      this.bulkUploadData = null;
      this.bulkUploadModalCtrl = false;
    },
    async uploadBulkProducts() {
      // console.log(JSON.stringify(this.bulkUploadData));
      this.isLoading = true;
      this.bulkUploadRes = await this.$store.dispatch(
        "UPLOAD_BULK_PRODUCTS",
        this.bulkUploadData
      );

      if (this.bulkUploadRes.status) {
        this.currenrtBulkUploadStage += 1;
        // this.$refs['selectBulkProductUploadDoc'].value = '';
        this.resetBulkUploadData();

        eventBus.$emit("trac-alert", {
          message: `Hi ${
            GET_USER_DATA().name
          }, you can surf through other features on the app while your upload progress is completing.`,
        });
        this.$router.push({ name: "UploadedBatches" });
      } else {
        eventBus.$emit("trac-alert", {
          message: this.bulkUploadRes.message || "Error: Products failed to up",
        });
      }

      this.isLoading = false;
    },
    convertExcelFileToJSObjec() {
      readXlsxFile(this.bulkUploadFile, { schema })
        .then(({ rows, errors }) => {
          this.bulkUploadData = rows;
        })
        .catch((error) => {
          // console.log(error.message);
          eventBus.$emit("trac-alert", {
            message:
              "Error: Data in file does not match the required template.",
          });
        });
    },
    async fetchAllStores() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
      this.isLoading = false;
    },
    async createStore() {
      this.isLoading = true;
      if (GET_LOCAL_DB_DATA("store-edit-details")) {
        await this.$store.dispatch("UPDATE_STORE", this.store);
      } else {
        await this.$store.dispatch("CREATE_STORE", this.store);
      }
      const res = this.$store.getters["GET_STORE_RES"];

      this.$store.commit("STORE_ACTION_RES", null);

      if (res.status) {
        // Update local store
        const user = GET_USER_DATA();
        const token = GET_USER_TOKEN();

        user.stores.push({ id: res.data._id, name: res.data.name });

        SAVE_LOCAL_DB_DATA("traction-app-user-data", { token, user });
        if (GET_LOCAL_DB_DATA("store-edit-details")) {
        } else {
          // Add newly created store to products store stocks
          await this.$store.dispatch("FETCH_ALL_PRODUCTS");
          const productsRes = this.$store.getters["GET_ALL_PRODUCT"];

          if (productsRes.status) {
            productsRes.items.forEach((product) => {
              if (product.variation) {
                product.varieties.forEach((variant) => {
                  variant.store_stock.push({
                    re_order: 0,
                    stock: 0,
                    store_id: res.data._id,
                    store_name: res.data.name,
                  });
                });
              } else {
                (product.store_stock || []).push({
                  re_order: 0,
                  stock: 0,
                  store_id: res.data._id,
                  store_name: res.data.name,
                });
              }
            });

            await this.fetchAllStores();
          }
        }

        this.addStoreModalCtrl = false;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>